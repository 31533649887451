<template>
  <div>
   <loading />
  </div>
</template>
<script>
import Loading from '@/components/Loading.vue';
export default {
  components: { Loading },
  created(){
        var data = JSON.parse(localStorage.getItem('data'))

        var stripeUrl = document.referrer.indexOf('https://checkout.stripe.com')

        if(stripeUrl != -1 && this.$route.params.sessionId && data){ 
           this.createOrder(data) 
        }else{
            this.$router.push('/')
        }
  },
  methods: {    
    createOrder(orderData) {
      this.axios
        .post("/webapi/Orders/createOrder", { data: orderData })
        .then((res) => {
          if (res.data.code != 200) {
            Dialog.alert({
              message: res.data.message,
              confirmButtonText: "OK",
            });
          } else {
            this.$store.commit("emptyCart");
            localStorage.setItem('data', null)
            setTimeout(()=>{this.$router.push({
              path: "/success/" + res.data.data.order_number,
            });},1000)
          }
        });
    },
  },
};
</script>